import useNumberFormatter, {
  type UseNumberFormatReturn,
  type UseNumberFormatOptions,
} from './useNumberFormatter'

/**
 * Хук для форматирования фиатных валют.
 * Формат зависит от локали i18n.
 * Каждый параметр может быть передан как Ref для реактивного обновления.
 * Максимум 2 знака после запятой.
 * Если есть десятичные дроби добавляет сотые 100.1 => 100.10
 *
 * @example
 * const { formatCurrency } = useCurrencyFormatter({ currency: 'USD' })
 * format(1000.1) // 1 000.10 $
 */
export default function useCurrencyFormatter(
  options: UseNumberFormatOptions = {},
): UseNumberFormatReturn {
  const fullOptions: UseNumberFormatOptions = { ...options }
  if (options.currency) {
    fullOptions.style = 'currency'
    fullOptions.currency = options.currency
  }
  const { format: numberFormat } = useNumberFormatter({
    currency: options.currency,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
    ...fullOptions,
  })

  const { format: zeroFormat } = useNumberFormatter({
    ...fullOptions,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'auto',
  })

  function format(amount: number | string): string {
    return Number(amount) === 0 ? zeroFormat(amount) : numberFormat(amount)
  }

  return {
    format,
  }
}
